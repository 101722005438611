@import-normalize;
@import "layouts/index.scss";

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Heavy.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Medium.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Light.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box !important;
  font-family: "Avenir LT Std";
  font-weight: 600;
}

strong,
b {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.switch-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  will-change: opacity, transform;
  height: 100%;
  width: 100%;
}

.user-avatar-fallback {
  height: 100%;
  width: 100%;
}

.keyboard-datepicker .MuiOutlinedInput-adornedEnd {
  padding-right: 10px;
  .MuiIconButton-root {
    padding: 8px;
  }
}

.MuiTypography-root.MuiPickersMonth-root.MuiPickersMonth-monthDisabled {
  color: #959faf;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f7f8fa inset !important;
}

.MuiPaper-root.MuiDialog-paper.MuiPaper-rounded {
  max-width: 100vw;
}
